<template lang="html">
  <div class="Table">
    <div class="TableScrollable__wrapper">
      <TableHeader
        @sortChanged="orderBy"
        :ordered-by="orderedBy"
        :allChecked="allChecked"
        :checked="checked"
        @markAll="markAll"
        :columns="table.header"
      />

      <main class="Table__body">
        <RatingItem
          class="TableItem TableItem--withoutHover"
          v-for="item in chunkedList[this.listQuery.page - 1]"
          :key="item.id"
          :content="item"
        ></RatingItem>
      </main>
    </div>

    <footer>
      <Pagination
        :total="this.items.length"
        :page="this.listQuery.page"
        :limit="this.listQuery.limit"
        @pagination="handlePagination"
      ></Pagination>
    </footer>
  </div>
</template>

<script>
  import RatingItem from '@/partials/RatingItem'
  // Mixins
  import TableMixin from '@/mixins/TableMixin'

  export default {
    name: 'RatingsTable',
    mixins: [TableMixin],
    components: { RatingItem },
    data: function() {
      return {
        table: {
          header: [
            {
              label: 'Rated by',
              size: 'medium',
              sorted: true,
              sortProp: 'author.name',
            },
            {
              label: 'Game details',
              size: 'flex',
              sorted: true,
              sortProp: 'lineup.game.event.name',
            },
            {
              label: 'Position',
              size: 'short',
              sorted: true,
              sortProp: 'lineup.position.short_name',
            },
            {
              label: 'Rating',
              size: 'short',
              sorted: true,
              sortProp: 'rating',
            },
          ],
        },
      }
    },
  }
</script>
