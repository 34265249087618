<template lang="html">
  <div class="Table">

    <simplebar class="TableScrollable">
      <div class="TableScrollable__wrapper">

        <TableHeader
        @sortChanged="orderBy"
        :ordered-by="orderedBy"
        :columns="table.header" />

        <main class="TableBody">
          <PlayerLineup v-for="item in chunkedList[this.listQuery.page - 1]" :key="item.id" :item="item" />
        </main>

      </div>
    </simplebar>

    <footer>
      <Pagination :total="this.total" :page="this.listQuery.page" :limit="this.listQuery.limit" @pagination="handlePagination"></Pagination>
    </footer>

  </div>
</template>

<script>
// Mixins
import TableMixin from '@/mixins/TableMixin'
import PlayerLineup from '@/partials/Table/PlayerLineupItem'

export default {
  name: 'Table',
  components: {PlayerLineup},
  mixins: [TableMixin],
  data: function() {
    return {
      searchOptions: {
        keys: ['event.name', 'attendance', 'result']
      },
      table: {
        header: [
          {
            label: 'Event Name',
            sorted: true,
            sortProp: 'event.name',
            size: 'flex',
          },
          {
            label: 'Game Name',
            size: 'medium',
          },
          {
            label: 'Date',
            sorted: true,
            sortProp: 'event.date',
            size: 'short',
          },
          {
            label: 'Court',
            size: 'medium',
          },
          {
            label: 'Position',
            size: 'medium',
          },
        ]
      }
    }
  },
}
</script>
