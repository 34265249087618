<template lang="html">
  <div class="dashboard__section" v-if="userNot('player')">
    <action icon="add" @actionClicked="handleNewNote" class="mb-16"></action>
    <fade-transition mode="out-in">
      <CardLoader v-if="!loaded" />
      <div class="Card" v-if="loaded && notes && notes.length > 0">
        <Note v-for="note in notes" :note="note" :key="note.id" />
      </div>
      <empty-message v-if="loaded && !(notes && notes.length > 0)">
        Notes haven't been added to this player.
      </empty-message>
    </fade-transition>
    <vue-toggle-slide :active="addNewNote" class="pt-24">
      <div class="Card" >
        <NoteForm @noteAdded="handleAddedNote()" :player="player"/>
      </div>
    </vue-toggle-slide>
  </div>
</template>

<script>
import Note from '@/partials/Note'
import CardLoader from '@/components/Loaders/CardLoader'
import NoteForm from '@/components/Forms/NoteForm'
export default {
  name: 'PlayerNotes',
  components: {Note, CardLoader, NoteForm},
  props: {
    notes: {
      type: [Array, Boolean],
      required: true
    },
    player: {
      type: [Object, Boolean],
      required: true,
    },
    loaded: {
      type: Boolean,
      default: false,
    }
  },
  data: function () {
    return {
      addNewNote: false,
    }
  },
  methods: {
    handleNewNote() {
      this.addNewNote = !this.addNewNote
    },
    handleAddedNote() {
      this.addNewNote = false
    }
  }
}
</script>
