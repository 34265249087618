<template>
  <content-loader
    :width="400"
    :height="55"
    :speed="2"
    viewBox="0 0 55 200"
    :primaryColor="primaryColor"
    secondaryColor="#D1D1D1"
  >
    <rect x="0" y="0" rx="4" ry="4" width="130" height="5" />
    <rect x="140" y="0" rx="3" ry="3" width="130" height="5" />
    <rect x="280" y="0" rx="3" ry="3" width="90" height="5" />
    <rect x="0" y="15" rx="3" ry="3" width="90" height="5" />
    <rect x="115" y="15" rx="3" ry="3" width="60" height="5" />
    <rect x="185" y="15" rx="3" ry="3" width="200" height="5" />
    <rect x="0" y="30" rx="3" ry="3" width="130" height="5" />
    <rect x="145" y="30" rx="3" ry="3" width="120" height="5" />
    <rect x="275" y="30" rx="3" ry="3" width="95" height="5" />
    <rect x="0" y="45" rx="3" ry="3" width="130" height="5" />
    <rect x="145" y="45" rx="3" ry="3" width="225" height="5" />
  </content-loader>
</template>

<script>
  import { ContentLoader } from "vue-content-loader"
  export default {
    components: { ContentLoader },
    props: {
      primaryColor: {
        type: String,
        default: '#F1F4F6',
      }
    }
  }
</script>
