<template>
  <article class="TableItem TableItem--rating">
    <div class="RatingItem__author TableColumn TableColumn--medium">
      <div class="User User--reversed">
        <div class="ml-8 flex-col">
          <span class="text-bold ">{{ content.author.name }}</span>
        </div>
        <profile-pic
          :src="content.author.photo_url"
          :size="40"
          :alt="content.author.name"
        />
      </div>
    </div>
    <div class="TableColumn TableColumn--flex">
      <router-link
        :to="`/games/${content.lineup.game_id}`"
        class="RatingItem__event"
        v-if="content.lineup"
      >
        <div class="text-semi text-xs">
          {{ content.lineup.game.event.name }}
        </div>
        <div class="text-sm">
          {{ content.lineup.game.name }}
        </div>
      </router-link>
    </div>
    <div class="TableColumn TableColumn--short">
      <div class="RatingItem__details" v-if="content.lineup">
        <div class="Tag Tag--small">
          {{ content.lineup.position.short_name }}
        </div>
      </div>
    </div>
    <div class="TableColumn TableColumn--short">
      <div class="RatingItem__score">
        <svg-icon
          v-for="i in 5"
          :key="i"
          icon="star"
          :class="content.rating >= i ? 'filled' : ''"
        ></svg-icon>
      </div>
    </div>
  </article>
</template>

<script>
  export default {
    name: 'RatingItem',
    props: {
      content: {
        type: Object,
      },
    },
  }
</script>
