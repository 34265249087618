<template lang="html">
  <div class="Dashboard">
    <Breadcrumbs :title="loaded ? player.name : false" />
    <div v-if="isAvailable == false">
      <dashboard-top>
        <screen-title :title="this.$store.state.user.team.name"></screen-title>
      </dashboard-top>
      <div class="container-full">
        <div class="container--center">
          <empty-message> Player not found! </empty-message>
        </div>
      </div>
    </div>

    <div>
      <dashboard-top>
        <screen-title :title="loaded ? player.name : 'Loading..'" />
      </dashboard-top>

      <div class="container-full">
        <div class="section">
          <div class="section__col section__col--40">
            <div class="dashboard__section">
              <section-title title="Player Profile" />
              <PlayerProfile :player="player" :loaded="loaded" />
            </div>
          </div>
          <div class="section__col section__col--60">
            <tabs>
              <tab title="Notes" v-if="userNot('player')">
                <PlayerNotes :notes="notes" :player="player" :loaded="loaded" />
              </tab>
              <tab title="Games">
                <PlayerGames :games="games" :loaded="loaded" />
              </tab>
              <tab title="History">
                <PlayerSubmissions
                  :submissions="submissions"
                  :loaded="loaded"
                />
              </tab>
              <tab title="Ratings" v-if="userNot('player')">
                <Ratings :ratings="ratings" :loaded="loaded" :player="player" />
              </tab>
            </tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import PlayerSubmissions from './partials/Submissions';
  import PlayerNotes from './partials/Notes';
  import PlayerGames from './partials/Games';
  import Ratings from './partials/Ratings';
  import PlayerProfile from './partials/PlayerProfile';

  export default {
    name: 'SinglePlayer',
    components: {
      PlayerSubmissions,
      PlayerNotes,
      PlayerGames,
      PlayerProfile,
      Ratings,
    },
    mounted() {
      if (this.$route.path !== '/profile') {
        this.fetchUser(this.$route.params.id);
      } else if (this.userID) {
        this.fetchUser(this.userID);
      }
    },
    methods: {
      fetchUser(id) {
        this.$store.dispatch('players/GET_PLAYER', id);
      },
    },
    computed: {
      ...mapState({
        isAvailable: (state) => (state.players.current == false ? false : true),
        player: (state) =>
          state.players.current ? state.players.current : false,
      }),
      notes() {
        return this.player ? this.player.player_notes : false;
      },
      games() {
        let games = false;
        if (this.player) {
          games = this.player.player_lineups;
        }
        return games;
      },
      submissions() {
        return this.player ? this.player.submissions : false;
      },
      ratings() {
        return this.player ? this.player.ratings : false;
      },
      loaded() {
        let userId = this.$store.getters.getUserId;
        let param =
          this.$route.path == '/profile' ? userId : this.$route.params.id;
        return this.player && this.isAvailable && this.player.id == param;
      },
      userID() {
        return this.$store.getters.getUserId;
      },
    },
    watch: {
      userID(val) {
        if (val !== null && this.$route.path == '/profile') {
          this.fetchUser(val);
        }
      },
    },
  };
</script>
