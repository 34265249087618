<template lang="html">
  <div class="dashboard__section">
    <div class="mb-16">
      <Search @searched="handleSearch" />
    </div>
    <fade-transition mode="out-in">
      <CardLoader v-if="!loaded" />
      <div class="Card" v-if="loaded && submissions && submissions.length > 0">
        <PlayerSubmissions :items="submissions" :search-query="searchQuery" :key="submissions.length" />
      </div>
      <empty-message v-if="loaded && !(submissions && submissions.length > 0)">
        Player haven't participated in registrations.
      </empty-message>
    </fade-transition>
  </div>
</template>

<script>
import SearchMixin from '@/mixins/SearchMixin';
import PlayerSubmissions from '@/components/Tables/PlayerSubmissionsTable'
import CardLoader from '@/components/Loaders/CardLoader'

export default {
  name: 'Submissions',
  mixins: [SearchMixin],
  components: {PlayerSubmissions, CardLoader},
  props: {
    submissions: {
      type: [Array, Boolean],
      required: true
    },
    loaded: {
      type: Boolean,
      default: false,
    }
  }
}
</script>
