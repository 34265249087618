<template lang="html">
  <div class="dashboard__section" v-if="userNot('player')">
    <fade-transition mode="out-in">
      <CardLoader v-if="!loaded" />
      <div class="Card" v-if="loaded && ratings && ratings.length > 0">
        <RatingsTable :items="ratings"></RatingsTable>
      </div>
      <empty-message v-if="loaded && !(ratings && ratings.length > 0)">
        Player wasn't rated.
      </empty-message>
    </fade-transition>
  </div>
</template>

<script>
  import CardLoader from '@/components/Loaders/CardLoader'
  import RatingsTable from '@/components/Tables/RatingsTable'
  export default {
    name: 'PlayerNotes',
    components: { CardLoader, RatingsTable },
    props: {
      ratings: {
        type: [Array, Boolean],
      },
      player: {
        type: [Object, Boolean],
        required: true,
      },
      loaded: {
        type: Boolean,
        default: false,
      },
    },
    data: function() {
      return {
        isFormActive: false,
      }
    },
    methods: {
      handleForm() {
        this.isFormActive = !this.isFormActive
      },
      handleAddedNote() {
        this.addNewNote = false
      },
    },
  }
</script>
