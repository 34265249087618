<template>
  <content-loader
    :width="200"
    :height="2"
    :speed="2"
    viewBox="0 0 10 200"
    :primaryColor="primaryColor"
    secondaryColor="#D1D1D1"
  >
    <rect x="0" y="0" rx="2" ry="2" width="60" height="2" />
  </content-loader>
</template>

<script>
  import { ContentLoader } from "vue-content-loader"
  export default {
    components: { ContentLoader },
    props: {
      primaryColor: {
        type: String,
        default: '#F1F4F6',
      }
    }
  }
</script>
