<template lang="html">
  <div class="TableItem TableItem--withoutHover">
    <router-link
      :to="`/events/${item.event.id}`"
      class="TableColumn TableColumn--flex clickable"
      v-if="userNot('player')">
      <div class="ml-8">{{ item.event.name }}</div>
    </router-link>
    <div class="TableColumn TableColumn--flex" v-else>
      <div class="ml-8">{{ item.event.name }}</div>
    </div>
    <div class="TableColumn TableColumn--short">
      {{ convertTime(item.event.date) | moment('DD/MM/YYYY') }}
    </div>
    <div class="TableColumn TableColumn--medium">
      <tag-item :type="item.attendance">
        {{ item.attendance | capitalize }}
      </tag-item>
    </div>
    <div class="TableColumn TableColumn--medium">
      <tag-item :type="item.status">
        {{ item.status | capitalize }}
      </tag-item>
    </div>
  </div>
</template>

<script>
  import TimeHelper from '@/helpers/TimeHelper';
  export default {
    name: 'SubmissionTableItem',
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    methods: {
      convertTime: TimeHelper.convertUtcToLocal,
    },
  };
</script>
