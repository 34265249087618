<template lang="html">
  <section class="Card Profile">
    <fade-transition mode="out-in">
      <div class="Profile__pic" v-if="loaded">
        <profile-pic :src="player.photo_url" :alt="player.name" :size="80" />
        <h1 class="ml-16 title-4">{{ player.name }}</h1>
        <action
          icon="edit"
          v-if="userIs('admin')"
          @actionClicked="handleEditPlayer"
        ></action>
        <action
          icon="edit"
          v-if="userIs('player')"
          @actionClicked="
            $router.push({
              name: 'profile-settings',
              hash: '#account-&-profile-details',
            })
          "
        ></action>
        <rating
          v-if="userNot('player')"
          :rating="
            player.average_rating[0] ? player.average_rating[0].rating : null
          "
        />
      </div>
      <ProfilePictureLoader v-else />
    </fade-transition>
    <article class="Profile__attr Profile__attr--inline">
      <svg-icon icon="cake"></svg-icon>
      <fade-transition mode="out-in">
        <div class="Profile__attr__val" v-if="loaded">
          {{ player.birthday ? player.birthday : '-' }}
        </div>
        <LineLoader v-else />
      </fade-transition>
    </article>
    <article class="Profile__attr Profile__attr--inline">
      <svg-icon icon="email"></svg-icon>
      <fade-transition mode="out-in">
        <div class="Profile__attr__val" v-if="loaded">
          <a :href="`mailto:${player.email}`">{{ player.email }}</a>
        </div>
        <LineLoader v-else />
      </fade-transition>
    </article>
    <article class="Profile__attr">
      <h1 class="title-uppercase mb-8">Preffered Positions</h1>
      <fade-transition mode="out-in">
        <div class="Profile__attr__val" v-if="loaded">
          <div
            v-if="player.preffered_positions"
            class="Profile__attr__positions"
          >
            <tag-item
              v-for="position in player.preffered_positions"
              :key="position.id"
              >{{ position.short_name }}</tag-item
            >
          </div>
        </div>
        <LineLoader v-else />
      </fade-transition>
    </article>
    <article class="Profile__attr">
      <h1 class="title-uppercase mb-8">Competition Level</h1>
      <fade-transition mode="out-in">
        <div class="Profile__attr__val" v-if="loaded">
          {{ player.competition_level }}
        </div>
        <TextLoader v-else />
      </fade-transition>
    </article>
    <article class="Profile__attr">
      <h1 class="title-uppercase mb-8">Special Considerations</h1>
      <fade-transition mode="out-in">
        <div class="Profile__attr__val" v-if="loaded">
          {{
            player.special_considerations ? player.special_considerations : '-'
          }}
        </div>
        <LineLoader v-else />
      </fade-transition>
    </article>
    <article class="Profile__attr">
      <h1 class="title-uppercase mb-8">Emergency contact</h1>
      <fade-transition mode="out-in">
        <div class="Profile__attr__val" v-if="loaded">
          <div>
            {{
              player.emergency_contact_name
                ? player.emergency_contact_name
                : '-'
            }}
          </div>
          <a
            :href="`tel:${player.mergency_contact_number}`"
            v-if="player.emergency_contact_name"
            >{{ player.emergency_contact_number }}</a
          >
        </div>
        <LineLoader v-else />
      </fade-transition>
    </article>
  </section>
</template>

<script>
  import TextLoader from '@/components/Loaders/TextLoader'
  import LineLoader from '@/components/Loaders/LineLoader'
  import ProfilePictureLoader from '@/components/Loaders/ProfilePictureLoader'
  export default {
    name: 'PlayerProfile',
    components: { TextLoader, LineLoader, ProfilePictureLoader },
    props: {
      player: {
        type: [Object, Boolean],
        required: true,
      },
      loaded: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      handleEditPlayer() {
        this.$router.push({
          name: 'player-edit',
          params: { id: this.player.id },
        })
      },
    },
  }
</script>
