<template lang="html">
  <div class="dashboard__section">
    <div class="mb-16">
      <Search @searched="handleSearch" />
    </div>
    <fade-transition mode="out-in">
      <CardLoader v-if="!loaded" />
      <div class="Card" v-if="loaded && games && games.length > 0">
        <PlayerGames :items="games" :search-query="searchQuery" :key="games.length" />
      </div>
      <empty-message v-if="loaded && !(games && games.length > 0)">
        Player haven't played any games.
      </empty-message>
    </fade-transition>
  </div>
</template>

<script>
import SearchMixin from "@/mixins/SearchMixin";
import PlayerGames from "@/components/Tables/PlayerGamesTable";
import CardLoader from "@/components/Loaders/CardLoader";
export default {
  name: "Games",
  mixins: [SearchMixin],
  components: { PlayerGames, CardLoader },
  props: {
    games: {
      type: [Array, Boolean],
      required: true
    },
    loaded: {
      type: Boolean,
      default: false
    }
  }
};
</script>
