<template>
  <content-loader
    :width="400"
    :height="76"
    :speed="2"
    viewBox="0 0 400 160"
    :primaryColor="primaryColor"
    secondaryColor="#D1D1D1"
  >
    <rect x="86" y="38" rx="3" ry="3" width="185" height="9" />
    <circle cx="38" cy="38" r="38" />
  </content-loader>
</template>

<script>
  import { ContentLoader } from "vue-content-loader"
  export default {
    components: { ContentLoader },
    props: {
      primaryColor: {
        type: String,
        default: '#F1F4F6',
      }
    }
  }
</script>
